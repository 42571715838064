import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Sidebar from "../components/Sidebar";

export default class TextEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    return (
      <div style={{marginBottom:"500px"}}>
     <Sidebar/>
<div className="container-fluid content-page" style={{marginLeft:"-55px",display:"none"}}>

        <div className="row myrow">
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{border:"4px solid #dcdcdc",padding:"6px"}}>
        <div className="card text-black   mb-3">
  <div className="card-header bg-dark text-white"><h4>NOUVEL ARTICLE</h4></div>
  <div className="card-body">
    {/* <h5 class="card-title">Primary card title</h5> */}
        <div class="mb-3">
  <label for="titleArticle" className="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Titre de l'article</label>
  <input type="email" className="form-control" id="titleArticle" />
</div>
<div className="mb-3">
  <label for="formFileMultiple" class="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Image de l'en tête</label>
  <input className="form-control" type="file" id="formFileMultiple" multiple/>
</div>
<div class="mb-3">
<label for="conteArticle" className="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Contenu de l'article</label>
  {/*
 <label for="conteArticle" className="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Contenu de l'article</label>
  <textarea className="form-control" id="conteArticle" rows="10"></textarea> */}
  <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
          
          toolbar={{
            
            image: {uploadCallback :true},
            image: {alt: { present: true, mandatory: true }}
          }}
          
        />
        <textarea
          hidden
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        ></textarea>
</div>
<div className="mb-3">
<button type="button" class="btn btn-dark">Enregistrer</button>
</div>

</div>
</div>
</div>
 <div className="col-md-3">
 <div className="card text-black   mb-3">
   <div  className="card-header bg-dark text-white"><h4 class="card-title">IMAGE</h4></div>
     <div className="card-body">
     
        <div class="mb-3">
  <label for="exampleFormControlInput1" className="form-label">Email address</label>
  <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
</div>

</div>
 </div>
 </div>
 </div>
 </div>
        
      </div>
    );
  }
}

