import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
function Credit() {
  return (
    <>
    <div className="site-location-container">
        <p>
        <h3>CREDIT</h3>
         Vous êtes ici: ACCUEIL / NOS OFFRES / CREDIT
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
              <p>
                <h1> CREDIT </h1>
                </p>
              <img
              className="d-block w-100"
              src="../assets/images/epargne4.jpg"
              alt="First slide"
               />
               
                
              </div>
            </div>
            <div className="epargne-container-text">
              <p>
              {/* <h3>II. CREDIT</h3> */}
              <strong>
                Voici la liste de type de crédit que nous offrons à nos membres:
              </strong>
              <ul>
                <li>
                  <strong>1. Les crédit Ordinaires</strong>
                </li>
              </ul>
              Le remboursement est dégressif et les intérêts sont payés sur le
              capital restant dû. ils sont contractés plus pour les activités
              commerciales, l'entrepreneuriat/artisanat, l'amélioration de
              l'habitat, l'équipement l'agro-pastoral, les cas sociaux,... avec
              le taux d'intérêt de 2,5% / mois et l'échéance varie entre 12 et
              24 mois.
              <ul>
                <li>
                  <strong>2. Le crédit commercial</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres qui exercent les activités
              commerciales (business)
              <ul>
                <li>
                  <strong>3. Le crédit habitat</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres qui désirent se procurer des
              terrains, ameliorer leurs habitations ou construire des immeubles
              et autres infrastructures
              <ul>
                <li>
                  <strong>4.Le crédit agro-pastoral</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres agriculteurs professionnels et
              ceux qui font l'élevage des animaux de la basse-cour, des ovins et
              des bovins.
              <ul>
                <li>
                  <strong>5.Le crédit scolaire</strong>
                </li>
              </ul>
              La <strong>COOPEC AKIBA YETU</strong> finance les besoins de
              scolarité de ses membres et/ou de leurs enfants. Ces besoins de
              scolarité comprennent les frais scolaires/académiques, les
              fournitures scolaires, les livres, les exigences en habillement et
              équipement
              <ul>
                <li>
                  <strong>6.Le crédit mwangaza</strong>
                </li>
              </ul>
              Ces crédits concernent tous ceux qui veulent avoir du courant issu
              des panneaux solaires.
              <ul>
                <li>
                  <strong>7.Les crédits sociaux</strong>
                </li>
              </ul>
              Ces crédits concernent les besoins relatifs aux faits sociaux des
              membres comme le mariage/fête, les soins médicaux, les frais
              funéraires/deuil, les besoins alimentaires,le voyage,...
              <ul>
                <li>
                  <strong>8.Le crédit express</strong>
                </li>
              </ul>
              C'est une facilité de caisse ouverte à tous les membres qui, dans
              l'urgence, se trouvent dans l'obligation de dédouaner leurs
              marchandises, de payer des salaires ou de faire face à des besoins
              pressants à caractère économique ou autres. les intérêts sont à
              payer au début de l'échéance.
              <ul>
                <li>
                  <strong>9.Le crédit aux groupes solidaires</strong>
                </li>
              </ul>
              La <strong>COOPEC AKIBA YETU</strong> offre aux associations,
              groupes solidaires, aux gagne peu,... la possibilité d'accéder aux
              crédits pour le financement de leurs activités grâce à la caution
              solidaire.
              <ul>
                <li>
                  <strong>DUREE DU CREDIT</strong>
                </li>
              </ul>
              24 mois maximum c'est-à-dire 24 mensualités maximum.
              <ul>
                <li>
                  <strong>TYPE DE GARANTIE</strong>
                </li>
              </ul>
              <strong>- Le salaire </strong>
              Si l'employeur et l'emprunteur accepent la retenue mensuelle de la
              tranche de remboursement du crédit (principal plus intérêts)
              équivalant au tiers du salaire pendant toute la durée du crédit.
              <br />
              <strong>- La Caution solidaire </strong>
              Si les avaliseurs accepent d'être solidairement responsables du
              crédit sollicité par le membre ou le groupe; si le avaliseurs sont
              membres de la COOPEC , ceux-ci doivent être actifs et sans crédit.
              <br />
              <strong>- L'Hypothèque </strong>
              Un Certificat d'enregistrement ou un contrat de location d'une
              parcelle ayant une valeur supérieure d'au moins 100% du montant du
              crédit.
              </p>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default Credit;
