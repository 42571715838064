import React,{useState,useEffect, useContext} from "react";
import Sidebar from "../components/Sidebar";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { dateParser } from "../../components/utils/Utils";
import {BiEditAlt} from 'react-icons/bi'
import {AiOutlineDelete} from 'react-icons/ai'
import { Link } from "react-router-dom";
import { UidContext } from './../../components/AppContext';

const Articles = (props) => {
const [getData, setGetstate] = useState([])
const uid=useContext(UidContext)
useEffect(() => {
axios
.get(`${process.env.REACT_APP_API_URL}admin/articles`)
.then((response)=>{
setGetstate(response.data)
// console.log(getData)
}).catch((err)=>{
  console.log(err)
}) 
}, [])

const deleteArticle=()=>{
  let x=window.confirm("Voulez vous supprime c'est article ?")
  if (x==true) {
  axios 
  .get(`${process.env.REACT_APP_API_URL}admin/articles/delete/${props.match.params.id}`)
  .then((response)=>{
    console.log(response.data)
    // if(response.data.message===""){

    //   window.location=`/admin/articles`
    // }
  }).catch((err)=> console.log(err))
}else{
  return false
}
}
  return (
    <>
   {uid ? (

      <>
      <Sidebar />
      <div
        className="container-fluid content-page"
        style={{ marginLeft: "-55px" }}
      >
        <div className="row myrow">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ border: "4px solid #dcdcdc", padding: "6px" }}
          >
            <div className="card text-black   mb-3">
              <div className="card-header bg-dark text-white">
                <h4>ARTICLES</h4>
              </div>
              <div className="card-body">
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>TITRE DE L'ARTICLE</th>
                      {/* <th>S. TITRE</th>
                      <th>CONTENU</th> */}
                      <th>DATE PUBLICATION</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                  { getData.map((resultat,index)=>{
                      return(
                        <tr key={index}>
                        <td>{resultat.title_article}</td>
                        {/* <td>{resultat.sub_title}</td> */}
                        {/* <td>{resultat.content_article}</td> */}
                        <td>{dateParser(resultat.date_publication)}</td>
                        <td className="td-link"><Link to={`/admin/articles/edit-article/${resultat.idArticles}`}><BiEditAlt/></Link> <Link  to={`/admin/articles/delete/${resultat.idArticles}`}><AiOutlineDelete onClick={deleteArticle}/></Link>  </td>
                      </tr>  
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      ):
      (
        <div className="row">
        <div className="btn btn-danger" style={{float:"none",margin: "150px auto",width:"auto"}}>
        <h4>You are not allowed to access this page.</h4>
        </div>
       </div>
      )
    
    }
    </>
  );
};

export default Articles;
