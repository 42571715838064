import React from 'react';
import Sidebar from "../components/Sidebar";
const Team = () => {
  return (
    <div className='home'>
      <Sidebar/>
      <h1>Team</h1>
    </div>
  );
};

export default Team;






