import React, { useEffect, useState } from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import axios from "axios";
import { dateParser } from "../components/utils/Utils";


function Article(props) {
  const [article, setArticle] = useState([]);
  const [contentComment, setcontentComment] = useState("");
  const [dateComment, setDateComment] = useState(new Date());
  const [getComments,setGetComments] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}actualite/article/${props.match.params.id}`)
      .then((result) => {
        setArticle(result.data[0]);
        setGetComments(result.data)
        console.log(article)
        console.log(getComments)
        
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const commentHandle = (e) => {
    e.preventDefault();

    let messageShow=document.getElementById("messageShow");
    axios
      .post(`${process.env.REACT_APP_API_URL}actualite/article/${props.match.params.id}`, {
        comment: contentComment,
        datepost: dateComment,
      })
      .then((response) => {
        console.log(response.data);
        setcontentComment("")
        messageShow.innerHTML = response.data.message;
  response.data.message === "Commentaire bien posté."
  ? messageShow.classList.add("btn-success") 
  : messageShow.classList.add("btn-danger");
  // response.data.message === "Commentaire bien posté." && window.location.reload(true);
  setTimeout(() => {
   messageShow && messageShow.classList.add("d-none")
  
  }, 6000);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="site-location-container">
        <p>
          <h3>ARTICLE</h3>
          Vous êtes ici: ACCUEIL / ACTUALITE / ARTICLE
        </p>
      </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div
            className="row-article row"
            style={{ textAlign: "justify", margin: "20px auto" }}
          >
            <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
              ACTUALITE
            </h1>
            <h3
              style={{
                color: "steelblue",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >

              {article.title_article}
            </h3>
            <h6
              style={{
                color: "steelblue",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Publié {dateParser(article.date_publication)}
            </h6>
      <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
                <img
                  className="d-block w-100"
                  src={`https://akibayetu.ihdemunis.org/public/images/${article.image}`}
                  alt="First slide"
            />
                <p>
                  {article.sub_title ? (
                    <h5
                      style={{
                        color: "steelblue",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {article.sub_title}{" "}
                    </h5>
                  ) : null}
                </p>
              </div>
            </div>
            <div>
              <p style={{ fontSize: "20px" }}>{article.content_article}</p>
              <div className="commentaire-form">
                 {getComments.length ? getComments.map((result,index)=>{
                  
                  return(
                  
                    <div key={index}
                    style={{
                      background: "#289494",
                      color: "#fff",
                      padding: "4px",
                      display: "flex",
                      borderBottom: "1px solid #fff",
                      justifyContent:"space-between"
                    }}
                  >
                    { result.content_comm ?
                    <span>
                      <img
                        className="img"
                        style={{ height: "50px",borderRadius:"100px" }}
                        src="../../images/user_image.png"
                        alt="avatar"
                      />
                      <span>
                        <p style={{ fontSize: "15px" }}>
                          {result.content_comm}
                        </p>
                      </span>
                    </span>
                   : (<h3>Aucun commentaire disponible.</h3>) }
                    <br />
                    <span style={{ fontSize: "9px"}}>
                      {result.date_comm && dateParser(result.date_comm)}
                    </span>
                    <hr style={{fontSize:"5px"}}/>
                  </div>
                   
                  )
                })
                : null}
                
                <h4 className="commentaire-title">
                  Laisser nous un commentaire.
                </h4>
                <form>
                  <div
                    id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                      width: "auto",
                    }}
                  ></div>
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text"
                        onChange={(e) => setcontentComment(e.target.value)}
                        value={contentComment}
                        className="comment-texterea"
                      />
                      
                    </div>
                    <div className="col-md-6">
                      <button onClick={commentHandle}>Poster</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default Article;
