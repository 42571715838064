import React,{useState,useEffect} from 'react'
import { NavLink } from "react-router-dom";
import {ImMenu} from "react-icons/im";
import {ImCross} from "react-icons/im"
import {RiArrowDropDownLine} from "react-icons/ri"
import {
    Menu,
    MenuItem,
    MenuButton,
    // SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
function MiddleTopBar() {
const [showMenu,SetShowMenu] = useState(false)
const [hideMenu,SetHideMenu] = useState(false)
const [showItem,setShowItem] = useState(false);
const handleShowMenu=()=>{
let newClasse=document.getElementById("toogle-menu-container");
newClasse.classList.add("show-hidden-menu");
SetHideMenu(true)
setShowItem(true)
}
const handleHideMenu=()=>{
let removeNewClasse=document.getElementById("toogle-menu-container");  
removeNewClasse.classList.remove('show-hidden-menu')
SetHideMenu(false);
setShowItem(false)
}
useEffect(() => {
SetShowMenu(true)   
SetHideMenu(false)
setShowItem(false)
}, [showMenu])
    return (
        <>
        <div className="middle-top-bar">
            <ul className="menu-text-container">
            <li>
            <NavLink to='/'><h3>AKIBA YETU</h3></NavLink> 
            </li> 
            </ul>
            <ul className="menu-container">
            <li className="item item1">
           <NavLink exact  to="/">Accueil</NavLink>
           </li>
           <li className="item item2 toogle-li">
           <Menu className="sub-menu-toogle-container" menuButton={<MenuButton><span className="sub-menu-toogle">NOS OFFRES <RiArrowDropDownLine/></span></MenuButton>}>
            <MenuItem><NavLink to='/nos-offres/epargne'>EPARGNE</NavLink> </MenuItem>
            <MenuItem><NavLink to='/nos-offres/credit'>CREDIT</NavLink></MenuItem>
            <MenuItem><NavLink to='/nos-offres/formation'>FORMATION</NavLink></MenuItem>
            <MenuItem><NavLink to='/nos-offres/akiba-mobile'>AKIBA MOBILE</NavLink></MenuItem>
            {/* <SubMenu label="Open">
                <MenuItem>index.html</MenuItem>
                <MenuItem>example.js</MenuItem>
                <MenuItem>about.css</MenuItem>
            </SubMenu>
            <MenuItem>Save</MenuItem> */}
        </Menu>
           </li>
           <li className="item item2">
           <NavLink exact to="/service">Service</NavLink>
           </li>
           {/* <li className="item item4">
           <NavLink exact  to="/articles">Articles</NavLink>
           </li> */}
           <li className="item item3">
           <NavLink exact to="/about">Apropos</NavLink>
           </li>
           <li className="item item4">
           <NavLink exact to="/contact">Contact</NavLink>

           </li>
           <li className="item item5">
           <NavLink exact to="/partenaires">NOS PARTENAIRES</NavLink>

           </li>
           <li className="item item6">
           <NavLink exact to="/condition-adhesion">CONDITIONS D'ADHESION</NavLink>

           </li>
           <li className="item item7">
           <NavLink exact to="/faq">FAQ</NavLink>
           
           </li>
           </ul>
            <div>
           </div>
        </div>
        <ul className="toogle-menu-container" id="toogle-menu-container">
             <div className="spans-toogle-menu-container">
             {showMenu && <span onClick={handleShowMenu} className="span-menu-bars"><ImMenu/></span> } 
             {hideMenu && <span onClick={handleHideMenu} className="span-menu-bars-hide hide-span"><ImCross/></span> } 
              </div>
              {showItem && 
              <div className="toogle-item" id="toogle-item">
               <li className="item1">
               
           <NavLink exact  to="/">Accueil</NavLink>
           </li>
           <li className="item item2 toogle-li">
           <Menu className="sub-menu-toogle-container" menuButton={<MenuButton><span className="sub-menu-toogle">NOS OFFRES <RiArrowDropDownLine/></span></MenuButton>}>
            <MenuItem><NavLink to='/nos-offres/epargne'>EPARGNE</NavLink> </MenuItem>
            <MenuItem><NavLink to='/nos-offres/credit'>CREDIT</NavLink></MenuItem>
            <MenuItem><NavLink to='/nos-offres/formation'>FORMATION</NavLink></MenuItem>
            <MenuItem><NavLink to='/nos-offres/akiba-mobile'>AKIBA MOBILE</NavLink></MenuItem>
            {/* <SubMenu label="Open">
                <MenuItem>index.html</MenuItem>
                <MenuItem>example.js</MenuItem>
                <MenuItem>about.css</MenuItem>
            </SubMenu>
            <MenuItem>Save</MenuItem> */}
        </Menu>
           </li>
           <li className="item2">
           <NavLink exact to="/service">Service</NavLink>
           </li>
           {/* <li className="item3">
           <NavLink exact  to="articles">Articles</NavLink>
           </li> */}
           <li className="item4">
           <NavLink exact to="/about">Apropos</NavLink>
           </li>
           <li className="item5">
           <NavLink exact to="/contact">Contact</NavLink>

           </li>
           <li className="item8">
           <NavLink exact to="/faq">FAQ</NavLink>
           
           </li>
           <li className="item item5">
           <NavLink exact to="/partenaires">NOS PARTENAIRES</NavLink>

           </li>
           <li className="item item6">
           <NavLink exact to="/condition-adhesion">CONDITIONS D'ADHESION</NavLink>

           </li>
          
           
           </div>
           }
              </ul>
        </>
        
    )
}

export default MiddleTopBar
