export const  Data=[
    {
        question: "Qu'est ce que la COOPEC AKIBA YETU ?",
        answer : 'La COOPEC AKIBA YETU est une institution de micro-finances ayant son siège social à GOMA sur Avenue BUTEMBO N°23 Quartier LES VOLCANS, Commune de GOMA, ville de GOMA, Province du NORD-KIVU en République Démocratique du Congo.'
    },
    {
        question: 'Où et comment trouver la COOPEC AKIBA YETU ?',
        answer : 'LA COOPEC AKIBA YETU est située sur Avenue BUTEMBO N°23 Quartier LES VOLCANS, Commune de GOMA, ville de GOMA, Province du NORD-KIVU en République Démocratique du Congo.'
    },
    {
        question: 'LA COOPEC AKIBA YETU est elle agrée par la BCC ?',
        answer : "agréée sous le N° d'Agrément : Réf. Gouv./D.033/ N°000733 du 04 juin 2011 par la Banque Centrale du Congo."
    },
    {
        question: 'Depuis quand la COOPEC AKIBA YETU à commencer à fonctionner',
        answer : 'La COOPEC AKIBA YETU oeuvre depuis le 02/05/2008.'
    },
    {
        question: "Que est l'objet de la COOPEC AKIBA YETU ?",
        answer : 'Elle a pour objet la promotion sociale et économique de ses membres en collectant leurs épargnes et consentir des crédits à ceux-ci.'
    },
    {
        question: 'Quelle est vision de la COOPEC AKIBA YETU ?',
        answer : 'la COOPEC AKIBA YETU se veut être une structure financière autonome et disposant des fondamentaux performants de gestion, de contrôle, oeuvrant pour le dévéloppement socioéconomique de son milieu cible par sa rentabilité de façon régulière et sécuritaire.'
    },
    {
        question: 'Quelle est la MISSION de la COOPEC AKIBA YETU ?',
        answer : "Apporter de manière Efficace et efficiente , avec honnêteté et transparence , les services financiers de base. EPARGNE, CREDIT, FORMATION essentiellement aux gagne peu exclus des services financiers classiques en vue d'ameliorer leur niveau socioéconomique."
    }
];

