import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class Responsive extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  state = {
    display: true,
    
  };
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000
    };
    return (
      <div style={{
        display: this.state.display ? "block" : "none",
        height:"100px"
      }} className="patner-content">
         <h3>Nos partenaires</h3>
        {/* <h2>Auto Play & Pause with buttons</h2> */}
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div>
             <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
          <div>
          <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
          <div>
          <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
          <div>
          <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
          <div>
          <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
          <div>
          <p>
              <img
                className="d-block w-100"
                src="assets/images/image1.jpg"
                alt="First slide"
              />
          </p>
          </div>
        </Slider>
        {/* <div style={{ textAlign: "center" }}>
          <button className="button" onClick={this.play}>
            Play
          </button>
          <button className="button" onClick={this.pause}>
            Pause
          </button>
        </div> */}
      </div>
    );
  }
}