import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// import {MdWork} from "react-icons/md"
import { FaUserCircle } from "react-icons/fa";
// import {FaSchool} from "react-icons/fa"

function timeLine() {
  return (
    <>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#289494", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #289494" }}
          date="2011 - present"
          iconStyle={{ background: "#289494", color: "#fff" }}
          icon={<FaUserCircle />}
        >
          <h5 className="vertical-timeline-element-title">Mariame</h5>
          <h6 className="vertical-timeline-element-subtitle">Restauratrice</h6>
          <p>
            " J'ai agrandi mon magasin de pagnes grâce à un prêt COOPEC et mon
            chiffre d'affaire a quasiment doublé en seulement 2 ans "
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#289494", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #289494" }}
          date="2011 - present"
          iconStyle={{ background: "#289494", color: "#fff" }}
          icon={<FaUserCircle />}
        >
          <h5 className="vertical-timeline-element-title">Nestor</h5>
          <h6 className="vertical-timeline-element-subtitle">Cultivateur</h6>
          <p>
            " La COOPEC a financé mon projet d'acquisition de matériels et
            intrants agricoles, j’emploie moins de main d'œuvre et ma production
            a nettement augmenté "
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#289494", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #289494" }}
          date="2011 - present"
          iconStyle={{ background: "#289494", color: "#fff" }}
          icon={<FaUserCircle />}
        >
          <h5 className="vertical-timeline-element-title">Kakule</h5>
          <h6 className="vertical-timeline-element-subtitle">Planteur</h6>
          <p>
          " La COOPEC a financé mon projet d'acquisition de matériels et intrants agricoles, j’emploie moins de main d'œuvre et ma production a nettement augmenté "
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#289494", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #289494" }}
          date="2011 - present"
          iconStyle={{ background: "#289494", color: "#fff" }}
          icon={<FaUserCircle />}
        >
          <h5 className="vertical-timeline-element-title">Creative Director</h5>
          <h6 className="vertical-timeline-element-subtitle">Miami, FL</h6>
          <p>
            Creative Direction, User Experience, Visual Design, Project
            Management, Team Leading
          </p>
        </VerticalTimelineElement>
        {/* <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="April 2013"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<FaUserCircle />}
  >
    <h5 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h5>
    <h6 className="vertical-timeline-element-subtitle">Online Course</h6>
    <p>
      Strategy, Social Media
    </p>
  </VerticalTimelineElement> */}
        {/* <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2012"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<FaSchool />}
  >
    <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
    <p>
      Creative Direction, User Experience, Visual Design
    </p>
  </VerticalTimelineElement> */}
        {/* <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="2002 - 2006"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<FaSchool />}
  >
    <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
    <p>
      Creative Direction, Visual Design
    </p>
  </VerticalTimelineElement> */}

        {/* <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<FaStar />}
  /> */}
      </VerticalTimeline>
    </>
  );
}

export default timeLine;
