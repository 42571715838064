import React from "react";
import Fader from "./Fader";

function RightSideBar() {
  return (
    <div className="right-side">
      <div className="right-side-container">
        <span style={{ fontSize: "20px", textAlign: "center" }}>
          <p>
          <h3>QUI SOMMES NOUS ?</h3>
          La <strong>COOPEC AKIBA YETU</strong> est une institution de
          micro-finances ayant son siège social à GOMA sur Avenue BUTEMBO N°23
          Quartier LES VOLCANS, Commune de GOMA, ville de GOMA, Province du
          NORD-KIVU en République Démocratique du Congo. Elle oeuvre depuis le
          02/05/2008 et est agréée sous le N° d'Agrément : Réf. Gouv./D.033/
          N°000733 du 04 juin 2011 par la Banque Centrale du Congo. Elle a pour
          objet la promotion sociale et économique de ses membres en collectant
          leurs épargnes et consentir des crédits à ceux-ci.
          </p>
        </span>
        <div>
          <Fader />
        </div>
      </div>
    </div>
  );
}

export default RightSideBar;
