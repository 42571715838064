import React,{useState,useEffect,useContext} from 'react'
import { BiRefresh } from "react-icons/bi";
import Sidebar from "../components/Sidebar";
import axios from 'axios'
import { UidContext } from './../../components/AppContext';

function EditArticle(props) {
  const uid=useContext(UidContext)
const [getData,setGetData] = useState([])
const [titleArticle,setTitleArticle] = useState()
const [subTitle,setSubTitle] = useState("")
const [contentArticle,setContentArticle] = useState("")
const [datePublication,setDatePublication] = useState(new Date())
const [getIimage,setImage] = useState("")
const [IsLogin,setIsLogin]=useState([])

  useEffect(() => {
    axios   
    .get(`${process.env.REACT_APP_API_URL}admin/articles/edit-article/${props.match.params.id}`)
   .then((response)=>{
   setGetData(response.data[0])
   setTitleArticle(response.data[0].title_article)
   setSubTitle(response.data[0].sub_title)
   setContentArticle(response.data[0].content_article)
   setImage(response.data[0].image)
   setDatePublication(response.data[0].date_publication)
  
   console.log(getData)
   }).catch((error)=>{
       console.log(error)
   })
  
   setIsLogin(uid.isL)
   },[])

  


const EditArticle=()=>{
    const formData = new FormData()
    formData.append('title_article',titleArticle);
    formData.append('sub_title',subTitle);
    formData.append('content_article',contentArticle)
    formData.append('date_publication',datePublication)
    formData.append('uploaded_image',getIimage)
    const config = {
      Headers : {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'content-type' : 'multipart/form-data',
      },
    };
    const url= `${process.env.REACT_APP_API_URL}admin/articles/edit-article/${props.match.params.id}`;
        axios 
       .put(url,formData,config)
       .then((response)=>{
        let btnSave=document.getElementById("btnSave");
        let messageShow=document.getElementById("messageShow");
        btnSave.classList.add("disabled");
        // this.setState({ showMessage: true });
        messageShow.innerHTML = response.data.message;

        response.data.message === "L'article a été bien modifié."
          ? messageShow.classList.add("btn-success")
          : messageShow.classList.add("btn-danger");
       console.log(response.data)

       }).catch((error) => console.log(error)) 
}

const  refreshForm=(e)=>{
    e.preventDefault();
    let btnSave = document.getElementById("btnSave");
    let messageShow = document.getElementById("messageShow");
    btnSave.classList.remove("disabled");
    messageShow.innerHTML = "";
    messageShow.classList.remove("btn-danger");
    messageShow.classList.remove("btn-success");
}
    return (
        <div>
          {IsLogin ?
         (
           <>
            <Sidebar/>
          <div
          className="container-fluid content-page"
          style={{ marginLeft: "-55px" }}
        >
          <div className="row myrow">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ border: "4px solid #dcdcdc", padding: "6px" }}
            >
              <div className="card text-black   mb-3">
                <div className="card-header bg-dark text-white">
                  <h4>MODIFICATION DE L'ARTICLE</h4>
                </div>
                <div className="card-body">
                {/* <form  > */}
                <div
                    id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                    }}
                  ></div>
                  <div class="mb-3">
                    <label
                      for="titleArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Titre de l'article 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titleArticle" 
                      value={titleArticle}
                      onChange={(e) => setTitleArticle(e.target.value)}
                      
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="subTitleArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Sous titre de l'article
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subTitleArticle" 
                      value={subTitle}
                      onChange={(e) => setSubTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      for="formFileMultiple"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Image de l'en tête
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFileMultiple"
                      // multiple
                    //   value={getData.image}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="datePublication"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Date de publication
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="datePublication"
                    
                      
                      onChange={(e) => setDatePublication(e.target.value)}
                    />
                  </div>

                  <div class="mb-3">
                    <label
                      for="conteArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Contenu de l'article
                    </label>
                    
 <label for="conteArticle" className="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Contenu de l'article</label>
  <textarea className="form-control" id="conteArticle" rows="10" value={contentArticle}   onChange={(e) => setContentArticle(e.target.value)}></textarea>
                    {/* <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: { uploadCallback: true },
                        image: { alt: { present: true, mandatory: true } },
                      }}
                      onClick={(e) =>
                        this.setState({
                          content_article: draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        })
                      }
                    /> */}
                  </div>

                  {/* <div class="mb-3">
                    <label
                      for="formFileMultiple"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Ecrire quelque chose ici.
                    </label> */}
                    {/* <input
                      required
                      type="text"
                      className="form-control" placeholder="exemple OK"
                      style={{ width: "110px" }}
                      onChange={() =>
                        this.setState({
                          content_article: draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        })
                      }
                    /> */}
                  {/* </div> */}
                  <div className="mb-3">
                    <button
                     onClick={EditArticle}
                      type="button"
                      class="btn btn-dark"
                      id="btnSave"
                    >
                      Enregistrer la modification
                    </button>
                    <button
                      onClick={refreshForm}
                      type="button"
                      class="btn btn-success"
                      style={{ marginLeft: "5px" }}
                    >
                      <BiRefresh /> Actulaliser{" "}
                    </button>
                  </div>
                {/* </form> */}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card text-black   mb-3">
                <div className="card-header bg-dark text-white">
                  <h4 class="card-title">IMAGE</h4>
                </div>
                <div className="card-body">
                  <div class="mb-3">
                    <img className="img-thumbnail" style={{width:"100%"}} src={`../../../up_load_images/${getData.image}`} alt="edit-imag"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
        ):
        (
   
          <div className="row">
          <div className="btn btn-danger" style={{float:"none",margin: "150px auto",width:"auto"}}>
          <h4>You are not allowed to access this page.</h4>
          </div>
         </div>
        )
        
        }
        
        </div>
    )
}

export default EditArticle
