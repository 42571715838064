import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";

function Service() {
  return (
    <>
    <div className="site-location-container">
        <p>
        <h3>SERVICE</h3>
         Vous êtes ici: ACCUEIL / SERVICE
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock card">
          <div className="centerblock-card-service">
            <p>
              <h3>NOS SERVICES</h3>
              <strong>Nous offrons à nos membres les services suivants:</strong>
              <ul className="mission-list">
                <strong>
                  {" "}
                  <li>Eparge</li>
                </strong>
                <strong>
                  <li>Credit</li>
                </strong>
                <strong>
                  <li>Akiba MOBILE</li>
                </strong>
                <strong>
                  <li>Formation</li>
                </strong>
              </ul>
              <h3>I. EPARGNE</h3>
              <strong>
                A la COOPEC AKIBA YETU nous organisons 2 type d'épargne
              </strong>
              <ul>
                <li>
                  <strong>1. Epargne à vue</strong>
                </li>
              </ul>
              Elle peut être comprise comme une épargne libre (selon la capacité
              financière du membre), accessible au membre à tout moment qu'il
              peut vouloir en jouir.
              <ul>
                <li>
                  <strong>2. Epargne à terme</strong>
                </li>
              </ul>
              Elle est faite par des personnes désireuses de fructifier leurs
              fonds à la COOPEC. Cette dernière favorise ce genre d'épargne car
              utile pour les placements en vue de générer des intérêts. Ce taux
              est de 0.5% / mois et l'échéance est de 12 mois.
              <h3>II. CREDIT</h3>
              <strong>
                Voici la liste de type de crédit que nous offrons à nos membres:
              </strong>
              <ul>
                <li>
                  <strong>1. Les crédit Ordinaires</strong>
                </li>
              </ul>
              Le remboursement est dégressif et les intérêts sont payés sur le
              capital restant dû. ils sont contractés plus pour les activités
              commerciales, l'entrepreneuriat/artisanat, l'amélioration de
              l'habitat, l'équipement l'agro-pastoral, les cas sociaux,... avec
              le taux d'intérêt de 2,5% / mois et l'échéance varie entre 12 et
              24 mois.
              <ul>
                <li>
                  <strong>2. Le crédit commercial</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres qui exercent les activités
              commerciales (business)
              <ul>
                <li>
                  <strong>3. Le crédit habitat</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres qui désirent se procurer des
              terrains, ameliorer leurs habitations ou construire des immeubles
              et autres infrastructures
              <ul>
                <li>
                  <strong>4.Le crédit agro-pastoral</strong>
                </li>
              </ul>
              Ce crédit est accordé aux membres agriculteurs professionnels et
              ceux qui font l'élevage des animaux de la basse-cour, des ovins et
              des bovins.
              <ul>
                <li>
                  <strong>5.Le crédit scolaire</strong>
                </li>
              </ul>
              La <strong>COOPEC AKIBA YETU</strong> finance les besoins de
              scolarité de ses membres et/ou de leurs enfants. Ces besoins de
              scolarité comprennent les frais scolaires/académiques, les
              fournitures scolaires, les livres, les exigences en habillement et
              équipement
              <ul>
                <li>
                  <strong>6.Le crédit mwangaza</strong>
                </li>
              </ul>
              Ces crédits concernent tous ceux qui veulent avoir du courant issu
              des panneaux solaires.
              <ul>
                <li>
                  <strong>7.Les crédits sociaux</strong>
                </li>
              </ul>
              Ces crédits concernent les besoins relatifs aux faits sociaux des
              membres comme le mariage/fête, les soins médicaux, les frais
              funéraires/deuil, les besoins alimentaires,le voyage,...
              <ul>
                <li>
                  <strong>8.Le crédit express</strong>
                </li>
              </ul>
              C'est une facilité de caisse ouverte à tous les membres qui, dans
              l'urgence, se trouvent dans l'obligation de dédouaner leurs
              marchandises, de payer des salaires ou de faire face à des besoins
              pressants à caractère économique ou autres. les intérêts sont à
              payer au début de l'échéance.
              <ul>
                <li>
                  <strong>9.Le crédit aux groupes solidaires</strong>
                </li>
              </ul>
              La <strong>COOPEC AKIBA YETU</strong> offre aux associations,
              groupes solidaires, aux gagne peu,... la possibilité d'accéder aux
              crédits pour le financement de leurs activités grâce à la caution
              solidaire.
              <ul>
                <li>
                  <strong>DUREE DU CREDIT</strong>
                </li>
              </ul>
              24 mois maximum c'est-à-dire 24 mensualités maximum.
              <ul>
                <li>
                  <strong>TYPE DE GARANTIE</strong>
                </li>
              </ul>
              <strong>- Le salaire </strong>
              Si l'employeur et l'emprunteur accepent la retenue mensuelle de la
              tranche de remboursement du crédit (principal plus intérêts)
              équivalant au tiers du salaire pendant toute la durée du crédit.
              <br />
              <strong>- La Caution solidaire </strong>
              Si les avaliseurs accepent d'être solidairement responsables du
              crédit sollicité par le membre ou le groupe; si le avaliseurs sont
              membres de la COOPEC , ceux-ci doivent être actifs et sans crédit.
              <br />
              <strong>- L'Hypothèque </strong>
              Un Certificat d'enregistrement ou un contrat de location d'une
              parcelle ayant une valeur supérieure d'au moins 100% du montant du
              crédit.
              <h3>III. Akiba MOBILE</h3>
              C'est une nouvelle technologie que la COOPEC a mis en place pour
              permettre à tous ses membres de suivre à distance les mouvements
              dans leurs comptes. toute opération dans son compte est
              communiquée au membre par un SMS
              <h3>IV. Formation</h3>
              La COOPEC met en place un programme de formation et d'informations
              sur l'éducation financière et son fonctionnement en faveur de ses
              membres.
              <h3>V. CONDITION D'ADHESION</h3>
              . Adhérer volontairement à la COOPEC AKIBA YETU; <br />
              . Déposer 2 photos passeports récentes du membre et de ses
              mandataires; <br />
              <strong>. Pour la personne physique :</strong>  <br />
              Présenter la carte d'identité ou attestation de perte de pièces ou
              passeport en cours de validité ou permis de conduire valable;
              <br />
              <strong>. Pour la personne morale :</strong> <br />
              . Présenter une lettre demandant l'ouverture du compte et
              désignant le (les ) mandataires ; <br />
              . S'acquitter des frais d'adhésion variant entre 5, 10, 20, et
              50$;
              <br />. Solde minimum pour le compte est de 1$ ou son équivalent
              en FC.
            </p>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default Service;
