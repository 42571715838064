import React from 'react'
//import ErrorImage from '../components/images/ErrorImage.png'
// import TimeLine from '../components/timeLine'
// import HorizontalTimeLine from '../components/horizontalTimeLine'

function ErrorPage() {
    return (
        <>
            <div className="wrapper-error-page">
              {/* <HorizontalTimeLine/> 
              <TimeLine/>  */}
             
              </div>
        </>
            
            
    )
}

export default ErrorPage

