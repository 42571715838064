import React from 'react'
import {
    BrowserRouter as Router,
    // Redirect,
    Route,
    Switch,
  } from "react-router-dom";
import Content from "../Content";
import Login from "../../pages/Login";
// import Register from "../../pages/Register";
import Service from "../../pages/Service";
import About from "../../pages/About";
import Topbar from '../Topbar'
import Footer from '../Footer'
// import Articles from '../../pages/Articles'
import ErrorPage from '../../pages/ErrorPage'
import Faq from '../../pages/Faq'
import Epargne from '../../pages/Epargne';
import Credit from '../../pages/Credit';
import Formation from '../../pages/Formation';

import Articles from '../../admin/pages/Articles';
import { Reports, ReportsOne, ReportsTwo, ReportsThree } from '../../admin/pages/Reports';
import Team from '../../admin/pages/Team';
import Newarticle from '../../admin/pages/Newarticle';
import Product from '../../admin/pages/Product';
import Home from '../../admin/pages/Home';
import Article from '../../pages/Article';
import EditArticle from '../../admin/pages/EditArticle';
import AkibaMobile from '../../pages/AkibaMobile';
import RegisterAdmin from '../../admin/pages/RegisterAdmin';
import LoginAdmin from '../../admin/pages/LoginAdmin';
import NosPartenaires from '../../pages/NosPartaires';
import ConditionAdhesion from '../../pages/ConditionAdhesion';




function index() {
    return (
        <Router>
        <Topbar />
        <Switch>
          <Route path="/" exact component={Content } />
          <Route path="/contact" exact component={Login} />
          {/* <Route path="/register" exact component={Register} /> */}
          <Route path="/service" exact component={Service } />
          <Route path="/about" exact component={About} />
          <Route path="/actualite/article/:id" exact component={Article} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/nos-offres/epargne" exact component={Epargne} />
          <Route path="/nos-offres/credit" exact component={Credit} />
          <Route path="/nos-offres/formation" exact component={Formation} />
          <Route path="/nos-offres/akiba-mobile" exact component={AkibaMobile} />
          <Route path="/partenaires" exact component={NosPartenaires} />
          <Route path="/condition-adhesion" exact component={ConditionAdhesion} />
          {/* routes for admin page */}
          <Route path='/admin/home' exact component={Home} />
          <Route path='/admin/article' exact component={Articles} />
        <Route path='/admin/article/new' exact component={Newarticle} />
        <Route path='/admin/articles' exact component={Articles} />
        <Route path='/admin/reports' exact component={Reports} />
        <Route path='/admin/reports/contact' exact component={ReportsOne} />
        <Route path='/admin/reports/newsletter' exact component={ReportsTwo} />
        <Route path='/admin/reports/other-report' exact component={ReportsThree} />
        <Route path='/admin/articles/edit-article/:id' exact component={EditArticle} />
        <Route path='/admin/articles/delete/:id' exact component={Articles} />
        <Route path='/admin/register' exact component={RegisterAdmin} />
        <Route path='/admin/login' exact component={LoginAdmin} />
        <Route path='/admin/products' exact component={Product} />
        <Route path='/admin/team' exact component={Team} />
          <Route component={ErrorPage} />

          {/* <Redirect component={ErrorPage} /> */}
        </Switch>
        <Footer />
      </Router>
    )
}

export default index