import React from "react";
import MiddleTopBar from "./MiddleTopBar";
import logo3 from "./images/logo3.jpg";
// import Fader from "./Fader";
import { Link, NavLink } from "react-router-dom";
import { FaFacebook, FaWhatsappSquare, FaTwitterSquare } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";

function topbar() {
  return (
    <div>
      <div className="content-top">
        <div className="logo">
          <Link to="/">
            <img src={logo3} alt="logo" />
          </Link>
          <div className="item item1 logo-text">
            {/* <h3>MY COMPUNY</h3> */}
          </div>
          <div className="item item2 fade-container">
            {/* <Fader/> */}
            <div className="itemf">
              <span>
               <i> <AiFillPhone /></i>  Contactez-nous <br/><i className="i-contact"> (243) 970237272 </i></span>
         
            </div>
            <div className="itemf">
              <span>
              <i> <AiOutlineMail /></i> Ecrivez-nous <br/><i className="i-email">akibayetu@gmail.com</i> </span>
           
            </div>
          </div>
          <div className="item item3 login-text social-icons-container">
            <h6>
              <Link to="/">
                <FaFacebook />
              </Link>{" "}
              <Link to="/">
                <FaWhatsappSquare />
              </Link>{" "}
              <Link to="/">
                <FaTwitterSquare />
              </Link>
            </h6>
          </div>
        </div>
        {/* <div className="login-section-top">
          <div className="logoin-text">
              <h3>LOGIN</h3>
        </div>
        </div> */}
      </div>
      <MiddleTopBar />
    </div>
  );
}

export default topbar;
