import React, {useState,useEffect,useContext } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertToRaw } from "draft-js";
import { BiRefresh } from "react-icons/bi";

// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import draftToHtml from "draftjs-to-html";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import { UidContext } from './../../components/AppContext';



 const Newarticle=()=>{
  const uid=useContext(UidContext)
 const [titleArticle, setTitleArticle] = useState("")
 const [subTitle, setSubTitle] = useState("")
 const [contentArticle, setContentArticle] = useState("")
 const [datePublication, setDatePublication] = useState(new Date())
 const [uploadImage, setUploadImage] = useState("")
 const [loginStatus,setLoginStatus]=useState(false)
const [IsLogin,setIsLogin]=useState([])

 const postArticle=(e)=> {
    e.preventDefault();
    const formData = new FormData();
   
    formData.append('uploaded_image',uploadImage)
    formData.append('title_article',titleArticle);
    formData.append('sub_title',subTitle);
    formData.append('content_article',contentArticle)
    formData.append('date_publication',datePublication)
    const config = {
      Headers : {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'content-type' : 'multipart/form-data',
      },
    };
    let btnSave = document.getElementById("btnSave");
    
    // let input=document.getElementsByName("input");
    let messageShow = document.getElementById("messageShow");
    const url=`${process.env.REACT_APP_API_URL}admin/article/new`;
    axios
      .post(url,formData,config)
      .then((response) => {
        console.log(response.data);
        btnSave.classList.add("disabled");
        // this.setState({ showMessage: true });
        messageShow.innerHTML = response.data.message;
        response.data.message === "L'article a été bien publié."
          ? messageShow.classList.add("btn-success")
          : messageShow.classList.add("btn-danger");

        // input[0].value = '';
        // input[1].value = '';
        // input[2].value = '';
      })
      .catch((error) => {
        console.log(error);
      });
  }

 const  refreshForm=(e)=> {
    e.preventDefault();
    let btnSave = document.getElementById("btnSave");
    let messageShow = document.getElementById("messageShow");
    btnSave.classList.remove("disabled");
    // this.setState({
    //   title_article: "",
    //   content_article: "",
    //   image: "",
    //   date_publication: "",
    // });
    // this.setState({ showMessage: false });
    messageShow.innerHTML = "";
    messageShow.classList.remove("btn-danger");
    messageShow.classList.remove("btn-success");
    // window.location("/article/new")
  }
  useEffect(() => {
    setIsLogin(uid)
  }, [])

    return (
      <div>
        {IsLogin ?
         (
          <>
          <Sidebar/>
        <div
          className="container-fluid content-page"
          style={{ marginLeft: "-55px" }}
        >
          <div className="row myrow">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ border: "4px solid #dcdcdc", padding: "6px" }}
            >
              <div className="card text-black   mb-3">
                <div className="card-header bg-dark text-white">
                  <h4>NOUVEL ARTICLE</h4>
                </div>
                <div className="card-body">
                {/* <form  > */}
                <div
                    id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                    }}
                  ></div>
                  <div class="mb-3">
                    <label
                      for="titleArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Titre de l'article
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titleArticle"
                     
                      onChange={(e)=>setTitleArticle(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="subTitleArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Sous titre de l'article
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subTitleArticle"
                      onChange={(e)=>setSubTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      for="formFileMultiple"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Image de l'en tête
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFileMultiple"
                      // multiple
                      onChange={(e)=>setUploadImage(e.target.files[0])}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="datePublication"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Date de publication
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="datePublication"
            
                      onChange={(e) => this.setState({ date_publication: e.target.value })}
                      onChange={(e)=>setDatePublication(e.target.value)}
                    />
                  </div>

                  <div class="mb-3">
                    <label
                      for="conteArticle"
                      className="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Contenu de l'article
                    </label>
                    
 <label for="conteArticle" className="form-label" style={{color:"steelblue",fontWeight:"bold"}}>Contenu de l'article</label>
  <textarea className="form-control" id="conteArticle" rows="10"  onChange={(e)=>setContentArticle(e.target.value)}></textarea>
                    {/* <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: { uploadCallback: true },
                        image: { alt: { present: true, mandatory: true } },
                      }}
                      onClick={(e) =>
                        this.setState({
                          content_article: draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        })
                      }
                    /> */}
                  </div>

                  {/* <div class="mb-3">
                    <label
                      for="formFileMultiple"
                      class="form-label"
                      style={{ color: "steelblue", fontWeight: "bold" }}
                    >
                      Ecrire quelque chose ici.
                    </label> */}
                    {/* <input
                      required
                      type="text"
                      className="form-control" placeholder="exemple OK"
                      style={{ width: "110px" }}
                      onChange={() =>
                        this.setState({
                          content_article: draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          ),
                        })
                      }
                    /> */}
                  {/* </div> */}
                  <div className="mb-3">
                    <button
                     onClick={postArticle}
                      type="button"
                      class="btn btn-dark"
                      id="btnSave"
                    >
                      Enregistrer
                    </button>
                    <button
                      onClick={refreshForm}
                      type="button"
                      class="btn btn-success"
                      style={{ marginLeft: "5px" }}
                    >
                      <BiRefresh /> Actulaliser{" "}
                    </button>
                  </div>
                {/* </form> */}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-black   mb-3">
                <div className="card-header bg-dark text-white">
                  <h4 class="card-title">IMAGE</h4>
                </div>
                <div className="card-body">
                  <div class="mb-3">
                 {uploadImage &&  <img className="img-thumbnail" style={{width:"100%"}} src={`../../../up_load_images/${uploadImage}`} alt="edit-imag"/> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         </>
        ):
        (
   
          <div className="row">
          <div className="btn btn-danger" style={{float:"none",margin: "150px auto",width:"auto"}}>
          <h4>You are not allowed to access this page.</h4>
          </div>
         </div>
        )
        
        }
      </div>
    );
}

export default Newarticle
