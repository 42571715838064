import React from 'react'
import LeftSideBar from './LeftSideBar'
import RightSideBar from './RightSideBar'
import CenterBlock from './CenterBlock'

function content() {
    return (
        <div className="container">
             {/* <div className="side-left">
            <LeftSideBar/>
            </div> */}
            <div className="centerblock">
            <CenterBlock/>
            </div> 
            <div className="side-right">
            <RightSideBar/>
            </div>
        </div>
    )
}

export default content
