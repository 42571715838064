import React,{useState} from "react";
import axios from "axios"
// import Sidebar from "../components/Sidebar";

function RegisterAdmin() {
  const [username,setUserName] = useState("")
  const [password,setPassWord] =  useState("")
  
// axios.defaults.withCredentials= true
  const HandleSubmit = (e) =>{
    e.preventDefault();
    let messageShow=document.getElementById("messageShow");
     axios
     .post(`${process.env.REACT_APP_API_URL}admin/register`,({username:username,password:password})
     ).then((response)=>{
       console.log(response.data)
       messageShow.innerHTML = response.data.message;
        response.data.message ?   messageShow.classList.add("btn-danger") :
        messageShow.classList.add("btn-success")
        if(response.data.message==="register successfly"){
          window.location="/admin/login"
        }
        setTimeout(() => {

          messageShow && messageShow.classList.add("d-none")
         }, 6000);

     }).catch((err)=>console.log(err))
  }


  return (
    <>
      {/* <Sidebar /> */}
      <div
        className="container-fluid content-page"
        style={{ marginTop: "30px", marginBottom: "150px" }}
      >
        <div className="row myrow">
          <div className="col-md-3"></div>

          <div
            className="col-md-6"
            style={{ border: "4px solid #dcdcdc", padding: "6px" }}
          >
            <div
              className="container register-form"
              style={{ marginTop: "30px" }}
            >
              <div className="form">
                <div className="note">
                  <p>REGISTER.</p>
                </div>

                <div className="form-content">
                  <div
              id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                      width:"420px"
                    }}
                  ></div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Name *"
                         onChange={((e)=>setUserName(e.target.value))}
                        />
                      </div>
                      {/* <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number *"
                          value=""
                        />
                      </div> */}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Your Password *"
                          onChange={((e)=>setPassWord(e.target.value))}
                        />
                      </div>
                      {/* <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Confirm Password *"
                          value=""
                        />
                      </div> */}
                    </div>
                  </div>
                  <br/>
                  <button onClick={HandleSubmit} type="button" className="btnSubmit">
                    
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterAdmin;
