import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
// import depota1 from "../components/images/depota1.jpg"
// import image2 from "../components/images/depota2.jpg"
// import image3 from "../components/images/depota3.jpg"
function Epargne() {
  return (
    <>
    <div className="site-location-container">
        <p>
        <h3>EPARGNE</h3>
         Vous êtes ici: ACCUEIL / NOS OFFRES / EPARGNE
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
              <p>
                <h1> EPARGNE </h1>
                </p>
              <img
              className="d-block w-100"
              src="../assets/images/epargne3.jpg"
              alt="First slide"
               />
                
                
              </div>
            </div>
            <div className="epargne-container-text">
              <p>
              <strong>
                A la COOPEC AKIBA YETU nous organisons 2 type d'épargne
              </strong>
              <ul>
                <li>
                  <strong>1. Epargne à vue</strong>
                </li>
              </ul>
              Elle peut être comprise comme une épargne libre (selon la capacité
              financière du membre), accessible au membre à tout moment qu'il
              peut vouloir en jouir.
              <ul>
                <li>
                  <strong>2. Epargne à terme</strong>
                </li>
              </ul>
              Elle est faite par des personnes désireuses de fructifier leurs
              fonds à la COOPEC. Cette dernière favorise ce genre d'épargne car
              utile pour les placements en vue de générer des intérêts. Ce taux
              est de 0.5% / mois et l'échéance est de 12 mois.
              </p>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default Epargne;
