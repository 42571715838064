import React, { Component } from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
import {BiPhoneCall}  from "react-icons/bi";
//import Map from '../components/GoogleMap'
import axios from "axios";
import Recaptcha from "react-recaptcha";
class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    // this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.register = this.register.bind(this);
    this.state = {
      isVerified: false,
      username: "",
      email: "",
      telephone: "",
      request_service: "",
      adresse: "",
      date_contact: new Date(),
      registerStatus: "",
      isregistered:false
    };
  }

  // recaptchaLoaded() {
  //   console.log("recaptcha is ok");
  // }
  register() {
    // let redClasse=document.getElementById('dispayErrorElement');
    let errorName = document.getElementById("name");
    let errorPhone = document.getElementById("telephone");
    let errorService = document.getElementById("request_service");
    let redName = document.getElementById("redName");
    let redPhone = document.getElementById("redPhone");
    let redService = document.getElementById("redService");
    axios
      .post(`${process.env.REACT_APP_API_URL}contact`, {
        username: this.state.username,
        email: this.state.email,
        telephone: this.state.telephone,
        request_service: this.state.request_service,
        adresse: this.state.adresse,
        date_contact:this.state.date_contact
      })
      .then((response) => {
        if(response.data.message){
          if (response.data.message === "Veuillez renseigner votre nom") {
            errorName.classList.add("is-invalid");
            redName.classList.add("red");
            redName.innerHTML = "Veuillez renseigner votre nom";
          } else {
            errorName.classList.remove("is-invalid");
            redName.innerHTML = "";
          }
          if (
            response.data.message ===
            "Veuillez renseigner votre numéro de téléphone"
          ) {
            errorPhone.classList.add("is-invalid");
            redPhone.classList.add("red");
            redPhone.innerHTML = "Veuillez renseigner votre numéro de téléphone";
          } else {
            errorPhone.classList.remove("is-invalid");
            redPhone.innerHTML = "";
          }
  
          if (
            response.data.message ===
            "Veuillez nous renseigner de quel service vous souhaiter recevoir."
          ) {
            errorService.classList.add("is-invalid");
            redService.classList.add("red");
            redService.innerHTML =
              "Veuillez nous renseigner de quel service vous souhaiter recevoir.";
          } else {
            errorService.classList.remove("is-invalid");
            redService.innerHTML = "";
          }
        }else{
          this.setState({isregistered:true})
          //window.location="/";
        }
        //console.log(response.data.message);
      });
  }
  handleSubscribe(e) {
    e.preventDefault();

    if (this.state.isVerified) {
      this.register();
      
    } else {
      alert("Veuillez verifier que vous n'êtes pas un robot.");
    }
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }

  render() {
    return (
      <>
      <div className="site-location-container">
        <p>
        <h3>CONTACT</h3>
         Vous êtes ici: ACCUEIL / CONTACT
         </p>
         </div>
        <div className="container">
          <div className="side-left">
            <LeftSideBar />
          </div>
          <div className="centerblock card login-container">
            <h3 className="title-contact-form">
            CONTACT  
            </h3>
            <p>
              Bonjour merci d'avoir visité notre site officiel, veuillez nous
              laisser ici vos informations si vous êtes interessé par nos
              services nous vous appelerons au plus vite possible.
            </p>
            <br />
          {!this.state.isregistered ?
            (<form>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3 col-lg-12">
                    <label for="name" class="form-label">
                      Votre nom complet
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                      class="form-control"
                      id="name"
                    />
                    <div id="redName" className="form-text"></div>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <label for="telephone" class="form-label">
                      Votre téléphone{" "}
                    </label>
                    <input
                      type="tel"
                      onChange={(e) =>
                        this.setState({ telephone: e.target.value })
                      }
                      class="form-control"
                      id="telephone"
                    />
                    <div id="redPhone" className="form-text"></div>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <label for="email" class="form-label">
                      Email address(Facultatif)
                    </label>
                    <input
                      type="email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                    <div id="emailHelp" className="form-text">
                      Nous ne partagerons jamais votre e-mail avec quelqu'un
                      d'autre.
                    </div>
                  </div>
                  {/* <div className="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="mb-3 col-lg-12">
                    <label for="service" class="form-label">
                      Service attendue{" "}
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ request_service: e.target.value })
                      }
                      class="form-control"
                      id="request_service"
                    />
                    <div id="redService" className="form-text"></div>
                  </div>
                  <label for="adresse" class="form-label">
                    Votre adresse complete
                  </label>
                  <textarea
                    className="form-control"
                    onChange={(e) => this.setState({ adresse: e.target.value })}
                    id="adresse"
                    rows="3"
                  ></textarea>
                  <button
                    onClick={this.handleSubscribe}
                    type="submit"
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                 
                </div>
                <div className="mb-3 col-lg-12 mt-2">
                  <Recaptcha
                    sitekey="6LepWYAcAAAAABijyvzts7weyvCJrdbaviEx_Qvo"
                    render="explicit"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.recaptchaLoaded}
                  />
                </div>
              </div>
            </form>) :
            (<div className="col-12">
             <div className="success">
              Merci vos informations nous sont arrivées avec succès.
              </div> 
            </div>)
           }
            <p>
              Vous pouvez egalement nous appeler à ces numeros de téléphone
              <div className="contact-numbers-container col-md-6">
                <p><BiPhoneCall/>+243 970237272</p>
                <p><BiPhoneCall/>+243 000000000</p>
                <p><BiPhoneCall/>+243 000000000</p>
              </div>
            </p>
            {/* <div  className="google-maps-container">
<Map/> 
</div> */}
          </div>
          <div className="side-right">
            <RightSideBar />
          </div>
        </div>
      </>
    );
  }
}
export default Login;
