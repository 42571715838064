import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
// import depota1 from "../components/images/depota1.jpg"
// import image2 from "../components/images/depota2.jpg"
// import image3 from "../components/images/depota3.jpg"
function AkibaMobile() {
  return (
    <>
      <div className="site-location-container">
        <p>
          <h3>EPARGNE</h3>
          Vous êtes ici: ACCUEIL / NOS OFFRES / AKIBA YETU MOBILE
        </p>
      </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
              <p>
                  <h1> MOBILE MONEY </h1>
                </p>
                <img
                  className="d-block w-100"
                  src="../assets/images/mobile-image2.jpg"
                  alt="First slide"
                />
                
              </div>
            </div>
            <div className="epargne-container-text">
              <p>
                <strong>
                  La COOPEC AKIBA YETU integre une nouvelle technologie pour
                  améliorer d'avantage la qualité de ses differents services
                </strong>
                <ul>
                  <li>
                    <strong>1. SMS BANKING</strong>
                  </li>
                </ul>
                Il s'agit d'un nouveau service que  <strong>AKIBA YETU</strong>  offre à ses
                membres, desormais des lors que vous souscrivez à notre service
                SMS BANKING vous recevrez un message à chaque mouvement sur
                votre compte vous signalant l'état actuel de votre compte.
                <ul>
                  <li>
                    <strong>2. E-MAILING</strong>
                  </li>
                </ul>
                il s'agit d'un service qui permet à nos membres d'obtenir leurs
                relevé de compte directement dans leurs boites emails dès lors
                que le membre à souscrit ainsi il s'agit que le membre démande
                le relevé à un agent de la  <strong> COOPEC AKIBA YETU</strong> pour en recevoir.
                <ul>
                  <li>
                    <strong>2. MOBILE MONEY</strong>
                  </li>
                </ul>
                Ce service va permettre aux membres de la COOPEC AKIBA YETU
                d'effectuer des opérations <strong>DEPOT </strong>,{" "}
                <strong>RETRAIT</strong> partout où ils se trouvent. il sera
                lancé très bientot.
              </p>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default AkibaMobile;
