import React from 'react'
import Sidebar from "../components/Sidebar";
const Product = () => {
    return (
        <div>
            <Sidebar />
            PRODUCT
        </div>
    )
}

export default Product
