import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
// import depota1 from "../components/images/depota1.jpg"
// import image2 from "../components/images/depota2.jpg"
// import image3 from "../components/images/depota3.jpg"
function ConditionAdhesion() {
  return (
    <>
      <div className="site-location-container">
        <p>
          <h3>ADHESION</h3>
          Vous êtes ici: ACCUEIL / CONDITION D'ADHESION
        </p>
      </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
                {/* <img
                  className="d-block w-100"
                  src="../assets/images/mobile-image2.jpg"
                  alt="First slide"
                />
                */}
                 <p>
                   <h1> CONDITION D'ADHESION CHEZ AKIBA YETU </h1>
                 </p> 
                <div className="epargne-container-text mycard" style={{background:"#fff"}}>
              <p>
              . Adhérer volontairement à la COOPEC AKIBA YETU; <br />
              . Déposer 2 photos passeports récentes du membre et de ses
              mandataires; <br />
              <strong>. Pour la personne physique :</strong>  <br />
              Présenter la carte d'identité ou attestation de perte de pièces ou
              passeport en cours de validité ou permis de conduire valable;
              <br />
              <strong>. Pour la personne morale :</strong> <br />
              . Présenter une lettre demandant l'ouverture du compte et
              désignant le (les ) mandataires ; <br />
              . S'acquitter des frais d'adhésion variant entre 5, 10, 20, et
              50$;
              <br />. Solde minimum pour le compte est de 1$ ou son équivalent
              en FC.
              </p>
            </div>

              </div>
            </div>
            
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default ConditionAdhesion;
