import React, {useEffect,useState} from "react";
import Carousel from "./MiddleSliders";
import Responsive from "./CarouselP";
import TimeLine from "./timeLine";
import { Link } from "react-router-dom";
import axios from "axios";
function CenterBlock() {
  const [getData, setGetData] = useState([])
  const [newsLetterEmail,setNewsLetterEmail] = useState("")
  const [dateSouscr,setdateSouscr] = useState(new Date())
  
 useEffect(() => {
  axios 
  .get(`${process.env.REACT_APP_API_URL}`)
  .then((response)=>{
    setGetData(response.data)
    // console.log(response.data[0].title_article)
    console.log(setGetData)
  }).catch((error)=>{
    console.log(error)
  })
 

 }, [])

 const handleSubmitNewsLetter = (e) =>{
e.preventDefault();

let messageShow=document.getElementById("messageShow");
axios 
.post(`${process.env.REACT_APP_API_URL}news-letter`,{email:newsLetterEmail,dateS:dateSouscr})
.then((response)=>{
  console.log(response.data)
  messageShow.innerHTML = response.data.message;
  setNewsLetterEmail("")
  response.data.message === "Merci pour votre inscription à notre News Letter nous vous remercions."
  ? messageShow.classList.add("btn-success")
  : messageShow.classList.add("btn-danger");
  setTimeout(() => {

   messageShow && messageShow.classList.add("d-none")
   messageShow.classList.remove("btn-danger")
   messageShow.classList.remove("btn-success")
  }, 6000);

}).catch((err)=> console.log(err))
 }
  return (
    <div>
      <div className="card-sliders">
        <Carousel />
      </div>
      <div className="myrow-content row">
        <h3>Nos offres</h3>
        <div className="col-sm-12 col-md-4 col-lg-3">
        <Link to="nos-offres/epargne"> 
          <div className="mycard">
            <img
              className="d-block w-100"
              src="assets/images/epargne3.jpg"
              alt="First slide"
            />
            <h5>EPARGNE</h5>
            <p></p>
          </div>
          </Link>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
        <Link to="nos-offres/credit"> 
          <div className="mycard">
            <img
              className="d-block w-100"
              src="assets/images/epargne4.jpg"
              alt="First slide"
            />
            <h5>CREDIT</h5>
            <p></p>
          </div>
          </Link>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
        <Link to="nos-offres/formation"> 
          <div className="mycard">
            <img
              className="d-block w-100"
              src="assets/images/formation.jpg"
              alt="First slide"
            />
            <h5>FORMATION</h5>
            <p></p>
          </div>
          </Link>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
         <Link to="nos-offres/akiba-mobile"> 
         <div className="mycard">
            <img
              className="d-block w-100"
              src="assets/images/mobile-image.png"
              alt="First slide"
            />
            <h5>AKIBA MOBILE</h5>
            <p></p>
            
          </div>
          </Link>
        </div>
        <h3>Les micro-entrepreneurs témoignent</h3>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div>
            <TimeLine />
          </div>
        </div>
        <h3>Actualité</h3>
        
        { getData.map((data,index)=>{
          return(
            <div key={index} className="col-sm-12 col-md-4 col-lg-4">
            <div className="mycard2">
              <p style={{fontSize:"18px",fontWeight:"bold",textAlign:"center"}}>
                <Link to={`/actualite/article/`+data.idArticles}>
                <img
                  className="d-block w-100"
                  src={`https://akibayetu.ihdemunis.org/public/images/${data.image}`}
                  alt="First slide"
                />
                </Link>
                <Link to={`/actualite/article/${+data.idArticles}`}>
                {data.title_article.slice(0,80) +" ..."  }
                </Link>
              </p>
            </div>
          </div>

          )
           }) }

        <div className="center-block center-pathner">
          <Responsive />
        </div> 
        <div className="row form-news-letter">
          <div className="mycard2">
            
          <h3>Newsletter</h3>
            <form className="new-letter-form">
            <div
              id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                     width: "auto",
                    }}
                  ></div>
            <div className="row">
              <div className="col-md-6">
                Abonnez-vous à notre bulletin d'information périodique et rester
                informer de toute l’actualité de votre institution
              </div>
              <div className="col-md-6">
                <input onChange={(e)=>setNewsLetterEmail(e.target.value)} value={newsLetterEmail} type="email"  />
                <button onClick={handleSubmitNewsLetter}>Valider</button>
              </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CenterBlock;
