import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-bootstrap';

class PartenairesSlider extends Component {
    
    render() {
        return (
            <Carousel fade>
  <Carousel.Item>
  <div className="image-container">
    <img
      className="d-block w-100"
      src="assets/images/image1.jpg"
      alt="First slide"
    />
    </div>
    <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
  <div className="image-container">
    <img
      className="d-block w-100"
      src="assets/images/image2.jpg"
      alt="Second slide"
    />
    </div>
    <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
  <div className="image-container">
    <img
      className="d-block w-100"
      src="assets/images/image3.jpg"
      alt="Third slide"
    />
    </div>
    <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

        );
    }
};

//ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default PartenairesSlider