import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
import PartenairesSlider from "../components/PartenairesSlider"
function NosPartenaires() {
  return (
    <>
    
    <div className="site-location-container">
        <p>
        <h3>NOS PARTENAIRES</h3>
         Vous êtes ici: ACCUEIL / NOS PARTENAIRES
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
              <p>
                <h1> NOS PARTENAIRES </h1>
                </p>
              <PartenairesSlider/>
               
                
              </div>
            </div>
            <div className="epargne-container-text">
              <p>
              Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
              </p>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default NosPartenaires;
