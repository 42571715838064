import React from 'react'
import {NavLink} from 'react-router-dom'

function LeftSideBar() {
    return (
        <div className="left-side">
            <ul className="left-side-container">
            <h3>NOS VALEURS</h3>
            <li> <span>PROFESSIONALISME</span></li>
            <li> <span>HONNETETE</span> </li>
            <li> <span>SOLIDARITE</span> </li>
            
            {/* <li> <a href="#"> ITEM 4</a> </li>
            <li> <a href="#">ITEM 5</a> </li>
            <li> <a href="#">ITEM 6</a> </li>
            <li> <a href="#">TITEM 7</a> </li> */}
         
            </ul>
           
        </div>
              
    )
}
  

export default LeftSideBar
