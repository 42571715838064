import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
function About() {
  return (
    <>
    <div className="site-location-container">
        <p>
        <h3>A PROPOS</h3>
         Vous êtes ici: ACCUEIL / A PROPOS
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock card">
          <div className="centerblock-card-service">
            <p>
              <h3>HISTORIQUE</h3>
              La COOPEC AKIBA YETU est une institution de micro-finances ayant
              son siège social à GOMA sur Avenue BUTEMBO N°23 Quartier LES
              VOLCANS, Commune de GOMA, ville de GOMA, Province du NORD-KIVU en
              République Démocratique du Congo. Elle oeuvre depuis le 02/05/2008
              et est agréée sous le N° d'Agrément : Réf. Gouv./D.033/ N°000733
              du 04 juin 2011 par la Banque Centrale du Congo. Elle a pour objet
              la promotion sociale et économique de ses membres en collectant
              leurs épargnes et consentir des crédits à ceux-ci.
              <h3>NOTRE VISION</h3>
              la COOPEC AKIBA YETU se veut être une structure financière
              autonome et disposant des fondamentaux performants de gestion, de
              contrôle, oeuvrant pour le dévéloppement socioéconomique de son
              milieu cible par sa rentabilité de façon régulière et sécuritaire.
              <h3>NOTRE MISSION</h3>
              Apporter de manière <strong>Efficace</strong> et{" "}
              <strong>efficiente</strong> , avec <strong>honnêteté</strong> et{" "}
              <strong>transparence </strong>, les services financiers de base
              <ul className="mission-list">
                <strong>
                  {" "}
                  <li>Eparge</li>
                </strong>
                <strong>
                  <li>Credit</li>
                </strong>
                <strong>
                  <li>Akiba MOBILE</li>
                </strong>
                <strong>
                  <li>Formation Etc.</li>
                </strong>
              </ul>
              essentiellement aux gagne peu exclus des services financiers
              classiques en vue d'ameliorer leur niveau socioéconomique
              <h3>NOS VALEURS</h3>
              <ul className="mission-list">
                <strong>
                  {" "}
                  <ol>- Honnêté</ol>
                </strong>
                <strong>
                  <ol>- Professionnalisme</ol>
                </strong>
                <strong>
                  <ol>- Solidarité</ol>
                </strong>
              </ul>
            </p>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default About;
