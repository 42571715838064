import React,{useState,useEffect,useContext} from "react";
// import Sidebar from "../components/Sidebar";
import { Link } from 'react-router-dom';
import axios from "axios"
import { UidContext } from "../../components/AppContext";

function LoginAdmin() {
const [username,setUserName] = useState("")
const [password,setPassWord] =  useState("")
const [getData,setGetData]=useState([])
const [loginStatus,setLoginStatus]=useState(false)
const [IsLogin,setIsLogin]=useState([])
axios.defaults.withCredentials= true

const handleLogin=(e)=>{
  e.preventDefault()
  let messageShow=document.getElementById("messageShow");
  axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}admin/login`,
    withCredentials: true,
    data: {
      username,
      password,
    },
  })
  .then((response)=>{
    if(response.data.message){
      setLoginStatus(response.data.message)
      messageShow.innerHTML = response.data.message;
      response.data.message ?   messageShow.classList.add("btn-danger") :
      messageShow.classList.add("btn-success")
      console.log(loginStatus)

    }else{
      setLoginStatus(response.data[0].username)
      setGetData(response.data)
      window.location="/admin/home"
    }
    setTimeout(() => {

      messageShow && messageShow.classList.add("d-none")
     }, 6000);
  })
}

// useEffect(() => {
// axios
// .get(`${process.env.REACT_APP_API_URL}admin/login`).then((response)=>{
//   if(response.data.loggedIn === true)
//   {
//    setLoginStatus(response.data.user[0].username)

    
//   }
// })

// // setIsLogin(uid) 
// }, [])



  return (
    <>
      {/* {IsLogin ? <Sidebar /> :null } */}
      <div
     
        className="container-fluid register-form"
        style={{ marginTop: "20px", marginBottom: "250px", height: "300px" }}
      >
       
        <div className="row myrow" style={{marginTop:"80px"}}>
          <div className="col-md-4"></div>
          {/* <div className="col-md-3"></div> */}
          <div
            className="col-md-3"
            style={{ border: "4px solid #dcdcdc", padding: "6px" }}
          >
            <div class="login-reg-panel">
              <div class="white-panel">
                <div class="login-show " style={{ padding: "10px"}}>
                  <h2>LOGIN </h2>
                  <form >
                  <div
              id="messageShow"
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      margin: "5px auto",
                      width:"auto"
                    }}
                  ></div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        onChange={((e)=>setUserName(e.target.value))}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control mt-3"
                        type="password"
                        placeholder="Password"
                        onChange={((e)=>setPassWord(e.target.value))}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="btn btn-primary mt-3"
                        type="button"
                        value="Login"
                        onClick={handleLogin}
                      />
                    </div>

                    <Link to="">Forgot password?</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginAdmin;
