import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'HOME',
    path: '/admin/home',
    icon: <AiIcons.AiFillHome />
  },
  {
    title: 'ARTICLES',
    path: '/admin/articles',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Nouvel article',
        path: '/admin/article/new',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Tous les articles',
        path: '/admin/articles',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'RAPPORT',
    path: '/admin/reports',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Contact',
        path: '/admin/reports/contact',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'News Letter',
        path: '/admin/reports/newsletter',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Autres Rapports',
        path: '/admin/reports/other-report',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'PRODUCTS',
    path: '/admin/products',
    icon: <FaIcons.FaCartPlus />
  },
  {
    title: 'TEAM',
    path: '/admin/team',
    icon: <IoIcons.IoMdPeople />
  },
  {
    title: 'MESSAGES',
    path: '/admin/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Message 1',
        path: '/admin/messages/message1',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Message 2',
        path: '/admin/messages/message2',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'SUPPORT',
    path: '/admin/support',
    icon: <IoIcons.IoMdHelpCircle />
  }
];
