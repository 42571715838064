import React, { Component,useState,useEffect } from 'react'
import PropTypes from 'prop-types'; 


const Fader = ({text}) =>{
  const [fadeProp, setFadeProp] = useState({fade:'fade-in'});
 
  useEffect(() => {
      const timeout = setInterval(() => {
        Fader.defaultProps = {
          text: 'PROFESSIONALISME, HONNETETE, SOLIDARITE. ' 
        }
         if (fadeProp.fade === 'fade-in') {
           setFadeProp({
                fade: 'fade-out'
           })
        } else {
             setFadeProp({
                fade: 'fade-in'
             })
        }
      }, 4000);
    
   return () => clearInterval(timeout)
     }, [fadeProp])
     
  Fader.propTypes = {
  text:PropTypes.string
  }
  return (
    <>
   <h6 className={fadeProp.fade}>{text}</h6>
     </>
  )
}

export default Fader

