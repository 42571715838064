import React from "react";
import LeftSideBar from "../components/LeftSideBar";
import RightSideBar from "../components/RightSideBar";
function Formation() {
  return (
    <>
    
    <div className="site-location-container">
        <p>
        <h3>FORMATION</h3>
         Vous êtes ici: ACCUEIL / NOS OFFRES / FORMATION
         </p>
         </div>
      <div className="container">
        <div className="side-left">
          <LeftSideBar />
        </div>
        <div className="centerblock">
          <div className="row-article row">
            <div className="col-sm-12 col-md-6 col-lg-12 article-cards epargne-card">
              <div className="mycard">
              <p>
                <h1> FORMATION </h1>
                </p>
              <img
              className="d-block w-100"
              src="../assets/images/formation.jpg"
              alt="First slide"
               />
                
                
              </div>
            </div>
            <div className="epargne-container-text">
              <p>
              La COOPEC met en place un programme de formation et d'informations
              sur l'éducation financière et son fonctionnement en faveur de ses
              membres.
              </p>
            </div>
          </div>
        </div>
        <div className="side-right">
          <RightSideBar />
        </div>
      </div>
    </>
  );
}

export default Formation;
