import React from "react";
import { FaFacebook, FaWhatsappSquare, FaTwitterSquare } from "react-icons/fa";
import {BiPhoneCall}  from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
// import { AiOutlineMail } from "react-icons/ai";
import {Link} from "react-router-dom"
function Footer() {
  return (
    <>
      {/* <div className="top-footer">Footer</div> */}
      <div className="middle-footer">
        <div className="row-footer-1 row">
          <div className="col-sm-6 col-md-4 col-lg-3 colfoot1">
            <p>
              {" "}
              <h3> QUI SOMMES-NOUS? </h3> La COOPEC AKIBA YETU est une
              institution de micro-finances ayant son siège social à GOMA sur
              Avenue BUTEMBO N°23 Quartier LES VOLCANS, Commune de GOMA, ville
              de GOMA, Province du NORD-KIVU en République Démocratique du
              Congo.
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 colfoot2">
            <p>
              {" "}
              <h3> LA RÉALITÉ DES CHOSES: </h3>
              La COOPEC AKIBA YETU oeuvre depuis le 02/05/2008 et est agréée
              sous le N° d'Agrément : Réf. Gouv./D.033/ N°000733 du 04 juin 2011
              par la Banque Centrale du Congo. Elle a pour objet la promotion
              sociale et économique de ses membres en collectant leurs épargnes
              et consentir des crédits à ceux-ci.
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 colfoot3">
            <p>
              {" "}
              <h3> NOS ADRESSES: </h3> Avenue BUTEMBO N°23 Quartier LES VOLCANS,
              Commune de GOMA, ville de GOMA, Province du NORD-KIVU en
              République Démocratique du Congo.
            </p>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 colfoot4">
            <p style={{textAlign:"center"}}>
              {" "}
              <h3> CONTACT: </h3> 
              <ul>
                <li><h6><BiPhoneCall/>+243 970237272</h6></li>
                <li><h6><BiPhoneCall/> akibayetu@gmail.com</h6></li>
               
              </ul>
            </p>
          </div>
        </div>

        {/* <div className="social-icons-container">
          <div className="social-icons">
            <FaFacebook />
          </div>
          <div className="social-icons">
            <FaWhatsappSquare />
          </div>
          <div className="social-icons">
            <FaTwitterSquare />
          </div>
        </div> */}
      </div>
      <div className="copyright-container">
        <h6>
          CopyRight <strong>AKIBA YETU</strong> ©2021
          <span style={{float:"right"}} className="footerIcons"> <Link to="/">
                <FaFacebook />
              </Link>{" "}
              <Link to="/">
                <FaWhatsappSquare />
              </Link>{" "}
              <Link to="/">
                <FaTwitterSquare />
              </Link>
              <Link to="/admin/login">
                <FaUserCircle />
              </Link>
              
              
              </span>
        </h6>
      
      </div>
      {/* <div className="down-footer">
            Footer
            </div> */}
    </>
  );
}

export default Footer;
