import React, {useState} from 'react'
import LeftSideBar from '../components/LeftSideBar'
import RightSideBar from '../components/RightSideBar'
import {Data} from './FaqData'
import styled from 'styled-components'
import {IconContext} from 'react-icons'
import {FiPlus,FiMinus} from 'react-icons/fi'



const AccordionSection = styled.div `
display: flex;
flex-direction:column;
align-items:center;
justify-content:center;
position:relative;
height:100vh;
background:#fff;
`

const Container = styled.div `
position:absolute;
top:1px;
width:100%;
box-shadow:2px 10px 35px 1px rgba(153,153,153,0.3);

`

const Wrap = styled.div `
background: #dcdcdcdc;
color:#000;
display:flex;
justify-content:space-between;
align-items:center;
width:100%;
text-align:left;
cursor:pointer;
h3{
 padding:1rem;
 font-size:1rem; 
} 
span{
  margin:1.5rem;
}
`;

const Dropdown = styled.div `
background:#1c1c1c;
color:#fff;
width:100%;
height:100px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:left;
border-top: 1px solid #00ffb9;
border-top:1px solid #00ffb9;
p{
  font-size:1rem;
}
`;


const Faq =()=> {
const [clicked,setClicked] = useState(false)
const toggle = index => {
if(clicked===index){
  //if clicked question is already active then close it
  return setClicked(null)
}
setClicked(index)
}
    return (
      <>
      <div className="site-location-container">
        <p>
        <h3>FOIRE AUX QUESTIONS</h3>
         Vous êtes ici: ACCUEIL / FAQ
         </p>
         </div>
<div className="container">
           <div className="side-left">
          <LeftSideBar/>
          </div>
          <div className="centerblock card login-container">
          <IconContext.Provider value={{color : '#00FB9', size: '16px'}}>
          <AccordionSection >
          <Container>

            {Data.map((item,index)=>{
             return (
              <>
              <Wrap onClick={() => toggle(index)} key={index}>
              <h3>
                {item.question}
              </h3>
              <span>
                {clicked===index ? <FiMinus/> : <FiPlus/>}
              </span>
              </Wrap>
              
              {clicked===index ? (
                <Dropdown>
                <p>
                  {item.answer}
                </p>
               
                </Dropdown>
              ) : null
              
              
              }
              </> 
             )
            })}
          </Container>
            </AccordionSection> 
          </IconContext.Provider>
          </div>
          <div className="side-right">
          <RightSideBar/>
          </div>
          
      </div>
      </>
  )
  }

export default Faq