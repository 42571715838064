import React,{useState,useEffect} from "react";
import Sidebar from "../components/Sidebar";
import Table from 'react-bootstrap/Table'
import axios from "axios";
import { dateParser } from "../../components/utils/Utils";
// import {BiEditAlt} from 'react-icons/bi'
// import {AiOutlineDelete} from 'react-icons/ai'
// import { Link } from "react-router-dom";
export const Reports = () => {
  const [getData, setgetData] = useState([])
  useEffect(() => {
   axios
   .get(`${process.env.REACT_APP_API_URL}admin/reports`)
   .then((response)=>{
     setgetData(response.data)
     console.log(response.data)
   }).catch((err)=>console.log(err))
  }, [])
  return (
    <>
      <Sidebar />

      <div
        className="container-fluid content-page"
        style={{ marginLeft: "-55px" }}
      >
        <div className="row myrow">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ border: "4px solid #dcdcdc", padding: "6px" }}
          >
            <div className="card text-black   mb-3">
              <div className="card-header bg-dark text-white">
                <h4>  CONTACT RECU </h4>
              </div>
              <div className="card-body">
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>NOM</th>
                      {/* <th>S. TITRE</th>
                      <th>CONTENU</th> */}
                      <th>EMAIL</th>
                      <th>TELEPHONE</th>
                      <th>CONTENU</th>
                      <th>ADRESSE</th>
                      <th>TELEPHONE</th>
                    </tr>
                  </thead>
                  <tbody>
                  { getData.map((resultat,index)=>{
                      return(
                        <tr key={index}>
                        <td>{resultat.username}</td>
                        <td>{resultat.email}</td>
                        <td>{resultat.telephone}</td>
                        <td>{resultat.request_service}</td>
                        <td>{resultat.adresse}</td>
                        <td>{dateParser(resultat.date_contact)}</td>
                      </tr>  
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export const ReportsOne = () => {
  const [getData, setgetData] = useState([])
  useEffect(() => {
   axios
   .get("http://localhost:5000/admin/reports")
   .then((response)=>{
     setgetData(response.data)
     console.log(response.data)
   }).catch((err)=>console.log(err))
  }, [])
  return (
    <>
      <Sidebar />

      <div
        className="container-fluid content-page"
        style={{ marginLeft: "-55px" }}
      >
        <div className="row myrow">
          <div className="col-md-3"></div>
          <div
            className="col-md-9"
            style={{ border: "4px solid #dcdcdc", padding: "6px" }}
          >
            <div className="card text-black   mb-3">
              <div className="card-header bg-dark text-white">
                <h4>  CONTACT RECU </h4>
              </div>
              <div className="card-body">
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>NOM</th>
                      {/* <th>S. TITRE</th>
                      <th>CONTENU</th> */}
                      <th>EMAIL</th>
                      <th>TELEPHONE</th>
                      <th>CONTENU</th>
                      <th>ADRESSE</th>
                      <th>TELEPHONE</th>
                    </tr>
                  </thead>
                  <tbody>
                  { getData.map((resultat,index)=>{
                      return(
                        <tr key={index}>
                        <td>{resultat.username}</td>
                        <td>{resultat.email}</td>
                        <td>{resultat.telephone}</td>
                        <td>{resultat.request_service}</td>
                        <td>{resultat.adresse}</td>
                        <td>{dateParser(resultat.date_contact)}</td>
                      </tr>  
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReportsTwo = () => {
  const [getData, setgetData] = useState([])
  useEffect(() => {
   axios
   .get("http://localhost:5000/admin/reports/newsletter")
   .then((response)=>{
     setgetData(response.data)
    //  console.log(response.data)
   }).catch((err)=>console.log(err))
  }, [])
  return (
    <>
    <Sidebar />

    <div
      className="container-fluid content-page"
      style={{ marginLeft: "-55px" }}
    >
      <div className="row myrow">
        <div className="col-md-3"></div>
        <div
          className="col-md-9"
          style={{ border: "4px solid #dcdcdc", padding: "6px" }}
        >
          <div className="card text-black   mb-3">
            <div className="card-header bg-dark text-white">
              <h4>  PERSONNES INSCRITS AUX NEWS LETTER </h4>
            </div>
            <div className="card-body">
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>ADRESSE</th>
                    <th>DATE</th>

                  </tr>
                </thead>
                <tbody>
                { getData.map((resultat,index)=>{
                    return(
                      <tr key={index}>
                      <td>{resultat.emailVisiteur}</td>
                      <td>{dateParser(resultat.date_news_letter)}</td>
                    </tr>  
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export const ReportsThree = () => {
  return (
    <div className='reports'>
      <Sidebar/>
      <h1>Reports/Autre rapport</h1>
    </div>
  );
};
